//const baseUrl = "http://localhost/api/v1";
const baseUrl = "https://booking.futuresoundofegypt.com/api/v1";
const authUrl = `${baseUrl}/auth`;
const customersUrl = `${baseUrl}/customers`;
const eventsUrl = `${baseUrl}/events`;
const cartUrl = `${baseUrl}/carts`;
const paymentUrl = `${baseUrl}/ticket_order_payments`;
const orderUrl = `${baseUrl}/ticket_orders`;
const upgradeUrl = `${baseUrl}/ticket_upgrade_orders`;

export const routes = {
  base: baseUrl,

  login: `${customersUrl}/login/`,
  register: `${customersUrl}/signup/`,
  logout: `${authUrl}/logout/`,
  forgotPassword: `${customersUrl}/forgot-password/`,
  resetPassword: `${customersUrl}/reset-password/`,
  me: `${authUrl}/me/`,
  changePassword: `${authUrl}/change-password/`,
  changeInfo: `${customersUrl}/update-profile/`,

  eventsList: `${eventsUrl}/`,
  getEventById: (id) => `${eventsUrl}/${id}/`,
  ticketTiers: (id) => `${eventsUrl}/${id}/ticket-tiers/`,
  addons: (id) => `${eventsUrl}/${id}/add-ons/`,

  addTicketsToCart: `${cartUrl}/anonymous/`,
  cancelCart: `${cartUrl}/cancel/`,

  getOrderItems: (id) => `${orderUrl}/${id}`,
  ordersList: `${orderUrl}/`,

  payCart: `${paymentUrl}/`,
  submitReservation: `${baseUrl}/prepaid_reservation_orders/order/`,
  getReservations: `${baseUrl}/prepaid_reservation_orders/`,
  getReservationsById: (id) => `${baseUrl}/prepaid_reservation_orders/${id}/`,
  getFriendOrder: (id, token) =>
    `${baseUrl}/prepaid_reservation_orders/${id}/anonymous_order/?token=${token}`,
  payReservationAnonymously: (reservation, order_id) =>
    `${baseUrl}/reservations/${reservation}/orders/${order_id}/payments/anonymous/`,

  getTicketUpgradeOrder: (token) => `${upgradeUrl}/${token}/`,

  getInvitationOrder: (token) => `${baseUrl}/invitations/${token}/`,
  sendInvitationOrder: (id, portal) =>
    `${baseUrl}/invitations/${id}/${portal}/`,
  getInvitationReceipt: (id) => `${baseUrl}/prepaid_reservation_orders/${id}/`,
  payReservation: (reservation, order) =>
    `${baseUrl}/reservations/${reservation}/orders/${order}/payments/`,

  faqsList: `${baseUrl}/faqs/`,

  fetchUser: (email) => `${customersUrl}/email/?email=${email}`,

  anonymousRegister: `${customersUrl}/anonymous/`,
  createUser: `${customersUrl}/`,

  validateToken: (id) => `${eventsUrl}/${id}/early-register/validate-token/`,
};

export const AXIOS_DEFAULT_CONFIG = {
  withCredentials: true,
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFToken",
  contentType: "application/json",
};

export const VAT = 0.14;
export const entertainmentTax = 0.1;
export const commission = 0.05;

export const ticketLimit = 10;
export const warnAt = 3;

export const redirect_to = "https://booking.futuresoundofegypt.com";

export const seatsio_public_key = "20c7144b-0b02-4c37-be47-421be7be0778";

export const hardcoded_event = 33;

export const isOutsourcedEvent = true;

export const outsourcedEventProps = {
  primary_color: "000000",
  code: "3531",
  id: "E1021488",
  eventId: hardcoded_event,
  dataId: "1021488"
};
